import Vue from 'vue'
import { instance } from './plugins/api'

const getGeneric = async (url, data) => {
  try {
    const response = await instance.get(url, {
      params: data,
      headers: {
        Authorization: `Bearer ${await Vue.prototype.$auth.getAccessToken()}`
      }
    })
    return response.data.rows || response.data.data || response.data
  } catch (error) {
    const message = error.response ? error.response.data : error.message
    Vue.notify({
      group: 'dashboard',
      type: 'error',
      title: `GET request to "${url}" has failed`,
      text: message,
      duration: 10000
    })
    throw message
  }
}

const postGeneric = async (url, data) => {
  try {
    const response = await instance.post(
      url,
      { data },
      {
        headers: {
          Authorization: `Bearer ${await Vue.prototype.$auth.getAccessToken()}`
        }
      }
    )
    return response.data
  } catch (error) {
    const message = error.response ? error.response.data : error.message
    Vue.notify({
      group: 'dashboard',
      type: 'error',
      title: `POST request to "${url}" has failed`,
      text: message,
      duration: 10000
    })
    throw message
  }
}

const deleteGeneric = async (url, data) => {
  try {
    const response = await instance.delete(url, {
      params: { data },
      headers: {
        Authorization: `Bearer ${await Vue.prototype.$auth.getAccessToken()}`
      }
    })
    return response.data
  } catch (error) {
    const message = error.response ? error.response.data : error.message
    Vue.notify({
      group: 'dashboard',
      type: 'error',
      title: `DELETE request to "${url}" has failed`,
      text: message,
      duration: 10000
    })
    throw message
  }
}

const patchGeneric = async (url, data) => {
  try {
    const response = await instance.patch(
      url,
      { data },
      {
        headers: {
          Authorization: `Bearer ${await Vue.prototype.$auth.getAccessToken()}`
        }
      }
    )
    return response.data
  } catch (error) {
    const message = error.response ? error.response.data : error.message
    Vue.notify({
      group: 'dashboard',
      type: 'error',
      title: `PATCH request to "${url}" has failed`,
      text: message,
      duration: 10000
    })
    throw message
  }
}

// STATS
export const getStatsGeneral = (data) => getGeneric('/stats-general', data)
export const getStatsTags = (data) => getGeneric('/stats-tags', data)

// USERS
export const getUsers = (data) => getGeneric('/users', data)

// TAGS
export const getTags = (data) => getGeneric('/tags', data)

// EVENTS
export const getEvents = (data) => getGeneric('/events', data)
export const createEvent = (data) => postGeneric('/events', data)
export const deleteEvents = (data) => deleteGeneric('/events', data)
export const updateEvents = (data) => patchGeneric('/events', data)

// PROJECTS
export const getProjects = (data) => getGeneric('/projects', data)
export const createProject = (data) => postGeneric('/projects', data)
export const deleteProjects = (data) => deleteGeneric('/projects', data)
export const updateProjects = (data) => patchGeneric('/projects', data)

// EVENTS_COMMENTS_CATEGORIES
export const getEventsCommentsTypes = (data) => getGeneric('/events-comments-types', data)
export const createEventCommentType = (data) => postGeneric('/events-comments-types', data)
export const deleteEventsCommentsTypes = (data) => deleteGeneric('/events-comments-types', data)
export const updateEventsCommentsTypes = (data) => patchGeneric('/events-comments-types', data)

// PROJECTS_COMMENTS
export const getProjectsComments = (data) => getGeneric('/projects-comments', data)
export const createProjectComments = (data) => postGeneric('/projects-comments', data)
export const deleteProjectsComments = (data) => deleteGeneric('/projects-comments', data)
export const updateProjectsComments = (data) => patchGeneric('/projects-comments', data)

// PROJECTS_LIKES
export const getProjectsLikes = (data) => getGeneric('/projects-likes', data)
export const createProjectLike = (data) => postGeneric('/projects-likes', data)
export const deleteProjectsLikes = (data) => deleteGeneric('/projects-likes', data)
export const updateProjectsLikes = (data) => patchGeneric('/projects-likes', data)

// PROJECTS_PARTICIPANTS
export const getProjectsParticipants = (data) => getGeneric('/projects-participants', data)
export const createProjectParticipant = (data) => postGeneric('/projects-participants', data)
export const deleteProjectsParticipants = (data) => deleteGeneric('/projects-participants', data)
export const updateProjectsParticipants = (data) => patchGeneric('/projects-participants', data)

// PROJECTS_TAGS
export const updateProjectsTags = (data) => patchGeneric('/projects-tags', data)
