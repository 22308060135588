import Vue from 'vue'
import { ApolloClient, InMemoryCache, HttpLink, split, ApolloLink } from '@apollo/client/core'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { getMainDefinition } from '@apollo/client/utilities'

const useLocal = true
const isLocal = location.protocol === 'http:' && location.hostname === 'localhost'
const httpUri = useLocal && isLocal
  ? 'http://localhost:3000/graphql'
  : `https://api.jam-board.${process.env.CLUSTER}.cds.internal.unity3d.com/graphql`

const wsUri = httpUri.replace(/^http/, 'ws')

// ✅ Auth middleware for HTTP
const authLink = new ApolloLink(async (operation, forward) => {
  const token = await Vue.prototype.$auth.getAccessToken()
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`
    }
  }))
  return forward(operation)
})

// 🔗 HTTP link for queries/mutations
const httpLink = new HttpLink({
  uri: httpUri,
  fetch
})

// 🔌 WebSocket link for subscriptions
const wsLink = new GraphQLWsLink(createClient({
  url: wsUri,
  connectionParams: async () => {
    return {
      headers: {
        Authorization: `Bearer ${await Vue.prototype.$auth.getAccessToken()}`
      }
    }
  }
}))

// 🔀 Route subscriptions through WS, others through HTTP
const splitLink = split(
  ({ query }) => {
    const def = getMainDefinition(query)
    return (
      def.kind === 'OperationDefinition' &&
      def.operation === 'subscription'
    )
  },
  wsLink,
  authLink.concat(httpLink) // 👈 attach auth to HTTP requests
)

// 🚀 Apollo client
const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache()
})

export default client
